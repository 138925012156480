import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { PageSeo } from "../components/PageSeo"
import "./styles/careers.scss"

const Lifeatcredence = ({ data }) => {
  const { markdownRemark: markup } = data
  const { description, blurb } = markup.frontmatter
  return (
    <Layout>
      <section className="LifeatcredenceTemplate">
        <div className="container">
          <ReactMarkdown
            className="text-center py-3 py-lg-5"
            children={description}
          ></ReactMarkdown>
          {blurb.length &&
            blurb.map((item, index) => (
              <div key={`lifeatCredence_${index}`}>
                <div className={`row border-start mb-2 mb-lg-5`}>
                  <div className="col-12 col-lg-12 content">
                    <h4 className="text-uppercase text-muted subheading">
                      {item.heading}
                    </h4>
                    <ReactMarkdown children={item.content}></ReactMarkdown>
                  </div>
                  {item.image && (
                    <div className="col-12 col-lg-9 mb-3 ms-auto">
                      <GatsbyImage
                        image={item.image.childImageSharp.gatsbyImageData}
                        alt={item.heading}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </section>
    </Layout>
  )
}

export const lifeatCredencequery = graphql`
  query lifeatCredencequery {
    markdownRemark(frontmatter: { templateKey: { eq: "life-at-credence" } }) {
      id
      html
      frontmatter {
        name
        description
        blurb {
          heading
          content
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default Lifeatcredence
export const Head = () => (
  <PageSeo
    title="Life at Credence | Ranked #1 Investment Management Software Solution Provider"
    description="Life At Credence, knowledge is our forte, We Credencians love celebrations. We celebrate birthdays & tenure of our Credencians."
    keywords="work-life balance, career growth, in-office trainings, on the job trainings, career in information technology company, software company"
  />
)
